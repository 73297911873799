import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function StatsPanel({
  games = [],
  type,
  localType = "",
  setLocalType,
  state,
  setState,
  isEmbed,
}) {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    if (localType === "") {
      setStats(games.filter((item) => item.section === type));
      return;
    }

    let localGames = JSON.parse(localStorage.getItem(localType));
    if (!localGames) return;
    const nameOnly = games.map((item) => item.name);
    setStats(localGames.map((item) => games[nameOnly.indexOf(item)]));
  }, [games, type, localType]);

  if (isEmbed) return null;

  return (
    state && (
      <div className="search-panel">
        <div className="resultList">
          {stats.map((item, key) => (
            <Link
              onClick={() => setState(false)}
              to={`/game/${item.name}`}
              key={key}
              className="resultCard"
            >
              <div
                className="card-logo"
                style={{
                  backgroundImage: "url(" + item.squareImage + ")",
                }}
              ></div>
              <div className="card-title">{item.name}</div>
            </Link>
          ))}
          {stats.length === 0 && (
            <div key="404NotFound" className="resultCardNotFound">
              <i className="bx bx-category-alt"></i>
              <div className="card-title">Nothing is there</div>
            </div>
          )}
        </div>
        <button
          onClick={() => {
            if (localType !== "") {
              setLocalType("");
            }
            setState(false);
          }}
        >
          Close
        </button>
      </div>
    )
  );
}
