import React, { useEffect, useMemo, useState } from "react";
import "./MNav.scss";
import logo from "./res/logo.png";
import { Link, useLocation } from "react-router-dom";

import { useScrollDirection } from "react-use-scroll-direction";

export default function MNav({
  searchToggle,
  themeToggle,
  setStat,
  setLocalStat,
  statsToggle,
  randomGame,
  isEmbed,
}) {
  const { isScrollingUp, isScrollingDown } = useScrollDirection();
  const [hide, setHide] = useState(false);
  const [glow, setGlow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const handleScroll = () => {
    if (isScrollingUp && window.pageYOffset < 20) {
      setHide(false);
    }

    if (isScrollingDown) {
      setHide(true);
    }
  };

  window.addEventListener("scroll", handleScroll);

  useEffect(() => {
    setGlow(location.pathname.includes("/game/") ? true : false);
  }, [location.pathname]);

  const openStat = (value) => {
    setExpanded(false);
    searchToggle(false);
    themeToggle(false);
    statsToggle(true);
    setStat(value);
  };

  const openLocalStat = (value) => {
    openStat("");
    setLocalStat(value);
  };

  if (isEmbed) return null;

  return (
    <div
      className={`desktop-nav mobile-nav ${hide && "hide"} ${
        expanded && "expanded"
      }`}
    >
      <div className="top-bar">
        <Link to="/" className="nav-logo">
          <img className={`logo ${glow && "glow"}`} src={logo} alt="" />
          <div className={`logo-name ${glow && "glow"}`}>LEGiON</div>
        </Link>

        <div className="expander-cont">
          <i
            onClick={() => randomGame()}
            className="ri-exchange-funds-line mobile-nav-btn"
            title="Try Random Game"
          ></i>
          <i
            onClick={() => openLocalStat("favourites")}
            className="ri-heart-2-fill mobile-nav-btn"
            title="Your Favourites"
          ></i>
          <i
            onClick={() => openLocalStat("recents")}
            className="ri-history-line mobile-nav-btn"
            title="Recently Played"
          ></i>
          <i
            onClick={() => {
              themeToggle(true);
              searchToggle(false);
              statsToggle(false);
              setExpanded(false);
            }}
            className="bx bx-palette theme-btn"
            title="Site Theme"
          ></i>
          {!expanded ? (
            <i
              onClick={() => setExpanded(!expanded)}
              className="bx bx-menu"
            ></i>
          ) : (
            <i
              onClick={() => setExpanded(!expanded)}
              className="bx bx-chevron-up"
            ></i>
          )}
        </div>
      </div>

      <div className={`right-cont ${!expanded && "hide"}`}>
        <div className="nav-btn">Home</div>
        <div className="nav-btn" onClick={() => openStat("Most played")}>
          Most Played
        </div>
        <div className="nav-btn" onClick={() => openStat("Trending")}>
          Trending
        </div>
        <div className="nav-btn" onClick={() => openStat("New")}>
          Newest
        </div>
        <div className="nav-btn" onClick={() => openStat("Featured")}>
          Featured
        </div>
        <div
          onClick={() => {
            searchToggle(true);
            statsToggle(false);
            setExpanded(false);
            themeToggle(false);
          }}
          className="nav-btn"
        >
          Search
        </div>
        <div className="login-btn">Login</div>
      </div>
    </div>
  );
}
