import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Embed.scss";

export default function Embed({ games }) {
  const params = useParams();
  const [url, setUrl] = useState({});

  useEffect(() => {
    const gameName = params.name;
    let found = games.find(
      (item) => item.name.toLowerCase() === gameName.toLowerCase()
    );

    if (!found) {
      return;
    }

    setUrl(found.link);
  }, [games, params]);
  return (
    <iframe
      className="embedIframe"
      title="Game"
      src={url}
      allowFullScreen={true}
    ></iframe>
  );
}
