import "./App.scss";
import DNav from "./DNav";
import MNav from "./MNav";
import Home from "./pages/Home";

import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Play from "./pages/Play";
import SearchPanel from "./dpages/SearchPanel";
import { useEffect, useMemo, useState } from "react";
import Mode from "./dpages/Mode";
import Games from "./pages/Games";
import Notfound from "./pages/Notfound";
import Jsonmdfextract from "./components/Jsonmdfextract";
import StatsPanel from "./dpages/StatsPanel";
import Sidebar from "./core/Sidebar";
import Collection from "./pages/Collection";
import { shuffle } from "./res/data";
import Embed from "./pages/Embed";

function App() {
  const [showSearch, setShowSearch] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [stat, setStat] = useState("");
  const [localStat, setLocalStat] = useState("");
  const [showMode, setShowMode] = useState(false);
  const [games, setGames] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  // For Embed
  const isEmbed = useMemo(
    () => location.pathname.includes("/embed/"),
    [location]
  );

  useEffect(() => {
    fetch("https://raw.githubusercontent.com/Xperiement/repo/main/sample.json")
      .then((res) => {
        res.json().then((data) => {
          setGames(data);
        });
      })
      .catch((e) => {
        console.log("Failed to fetch game data");
        setGames([]);
      });
  }, []);

  return (
    <>
      <DNav
        setStat={setStat}
        setLocalStat={setLocalStat}
        statsToggle={setShowStats}
        searchToggle={setShowSearch}
        themeToggle={setShowMode}
        games={games}
        isEmbed={isEmbed}
        randomGame={() => {
          if (games.length > 0) {
            navigate(`/game/${shuffle(games)[0].name}`);
          }
        }}
      />
      <MNav
        setStat={setStat}
        setLocalStat={setLocalStat}
        statsToggle={setShowStats}
        searchToggle={setShowSearch}
        themeToggle={setShowMode}
        isEmbed={isEmbed}
        randomGame={() => {
          if (games.length > 0) {
            navigate(`/game/${shuffle(games)[0].name}`);
          }
        }}
      />
      <Sidebar
        setStat={setStat}
        setLocalStat={setLocalStat}
        statsToggle={setShowStats}
        searchToggle={setShowSearch}
        themeToggle={setShowMode}
        isEmbed={isEmbed}
        games={games}
      />
      <SearchPanel
        games={games}
        state={showSearch}
        setState={setShowSearch}
        isEmbed={isEmbed}
      />
      <StatsPanel
        games={games}
        type={stat}
        localType={localStat}
        setLocalType={setLocalStat}
        state={showStats}
        setState={setShowStats}
        isEmbed={isEmbed}
      />
      <Mode show={showMode} toggle={setShowMode} isEmbed={isEmbed} />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              games={games}
              heroGames={games.filter(
                (item) => item["featured (Y or N)"] === "Y"
              )}
            />
          }
        />
        <Route path="/embed/:name" element={<Embed games={games} />} />
        <Route path="/game/:name" element={<Play games={games} />} />
        <Route path="/category/:name" element={<Games games={games} />} />
        <Route
          path="/collection/:name"
          element={<Collection games={games} />}
        />
        <Route path="/404" element={<Notfound />} />
        <Route path="/dev" element={<Jsonmdfextract />} />
      </Routes>
    </>
  );
}

export default App;
