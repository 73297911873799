import React, { useEffect, useMemo, useRef, useState } from "react";
import "./DNav.scss";
import logo from "./res/logo.png";

import { useScrollDirection } from "react-use-scroll-direction";
import { Link, useLocation } from "react-router-dom";
import Search from "./Search";

export default function DNav({
  searchToggle,
  themeToggle,
  setStat,
  setLocalStat,
  statsToggle,
  games,
  randomGame,
  isEmbed,
}) {
  const location = useLocation();
  const { isScrollingUp, isScrollingDown } = useScrollDirection();
  const [hide, setHide] = useState(false);
  const [glow, setGlow] = useState(false);

  const handleScroll = () => {
    if (location.pathname.includes("/game/") && window.pageYOffset < 20) {
      setHide(false);
      return;
    }

    if (isScrollingUp && window.pageYOffset < 20) {
      setHide(false);
    }

    if (isScrollingDown) {
      setHide(true);
    }
  };

  window.addEventListener("scroll", handleScroll);

  useEffect(() => {
    setGlow(location.pathname.includes("/game/") ? true : false);
  }, [location.pathname]);

  const openStat = (value) => {
    searchToggle(false);
    themeToggle(false);
    statsToggle(true);
    setStat(value);
  };

  const openLocalStat = (value) => {
    openStat("");
    setLocalStat(value);
  };

  if (isEmbed) return null;

  return (
    <div className={`desktop-nav ${hide && "hide"}`}>
      <Link to="/" className="nav-logo">
        <img className={`logo ${glow && "glow"}`} src={logo} alt="" />
        <div className={`logo-name ${glow && "glow"}`}>LEGiON</div>
      </Link>
      <div className="right-cont">
        <Search games={games} />
        {/* <div className="nav-btn" onClick={() => openStat("Most played")}>
          Most Played
        </div>
        <div className="nav-btn" onClick={() => openStat("Trending")}>
          Trending
        </div> */}
        {/* <div className="nav-btn" onClick={() => openStat("New")}>
          Newest
        </div>
        <div className="nav-btn" onClick={() => openStat("Featured")}>
          Featured
        </div> */}
        {/* <div
          onClick={() => {
            searchToggle(true);
            statsToggle(false);
            themeToggle(false);
          }}
          className="nav-btn"
        >
          Search
        </div> */}
        <div
          onClick={() => randomGame()}
          className="nav-btn"
          title="Try Random Game"
        >
          <i className="ri-exchange-funds-line"></i>
        </div>
        <div
          onClick={() => openLocalStat("favourites")}
          className="nav-btn"
          title="Your Favourites"
        >
          <i className="ri-heart-2-fill"></i>
        </div>
        <div
          onClick={() => openLocalStat("recents")}
          className="nav-btn"
          title="Recently Played"
        >
          <i className="ri-history-line"></i>
        </div>
        <div
          onClick={() => themeToggle(true)}
          className="nav-btn"
          title="Site Theme"
        >
          <i className="bx bx-palette"></i>
        </div>
        <div className="login-btn">Login</div>
      </div>
    </div>
  );
}
