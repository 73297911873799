import React, { useState, useEffect, useMemo } from "react";
import "./Games.scss";
import { categories } from "../res/data";
import { useParams, useNavigate } from "react-router-dom";

export default function Games({ games }) {
  const params = useParams();
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("");
  const [categoryInfo, setCategoryInfo] = useState("");
  const [categoryBackground, setCategoryBackground] = useState(null);

  const subCategories = ["Girls Games", "Boys Games"];

  const data = useMemo(() => {
    if (params.name === "Girls Games") {
      return games.filter((item) => item["gender (B, G, Both)"] === "Both");
    } else if (params.name === "Boys Games") {
      return games.filter((item) => item["gender (B, G, Both)"] === "B");
    }

    return games;
  }, [games, params.name]);

  const handleItemClick = (key, url) => {
    navigate(url);
  };

  const tagFound = (tags) => {
    return tags.find((item) => item.toLowerCase() === params.name.toLowerCase())
      ? true
      : false;
  };

  useEffect(() => {
    const category = categories.find(
      (item) => item.title.toLowerCase() === params.name.toLowerCase()
    );

    if (!category) {
      navigate("/404", { replace: true });
    } else {
      setCategoryName(category.title);
      setCategoryInfo(category.info);
      setCategoryBackground(category.background ? category.background : null);
    }

    window.scrollTo(0, 0);
  }, [params]);

  return (
    <div className="games-container">
      <div
        className="games-hero"
        style={
          categoryBackground && {
            backgroundImage: `url('${categoryBackground}')`,
          }
        }
      >
        <p className="title">
          {categoryName}
          {categoryName.indexOf("Games") === -1 ? " Games" : null}
        </p>
        <p className="sub-title">{categoryInfo}</p>
      </div>

      <div className="game-list">
        {data.map(
          (item, key) =>
            (tagFound(item.category ? item.category.split(",") : []) ||
              subCategories.includes(params.name)) && (
              <div
                key={key}
                className="content-card"
                style={{
                  backgroundImage: "url(" + item.wideImage + ")",
                }}
                onClick={() => handleItemClick(key, `/game/${item.name}`)}
              >
                <div className="card-info">{item.name}</div>
              </div>
            )
        )}
      </div>
    </div>
  );
}
