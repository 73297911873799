import React, { useEffect, useState } from "react";
import "./SearchPanel.scss";
import { Link } from "react-router-dom";

export default function SearchPanel({ games, state, setState, isEmbed }) {
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    const nSearch = search.toLowerCase().trim();
    if (nSearch === "") {
      setSearchResult([]);
      return;
    }

    const f1 = games.filter((item) =>
      item.name.toLowerCase().includes(nSearch)
    );

    setSearchResult(f1);
  }, [search]);

  if (isEmbed) return null;

  return (
    state && (
      <div className="search-panel">
        <input
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder="Search game"
        ></input>

        <div className="resultList">
          {searchResult.map((item, key) => (
            <Link
              onClick={() => setState(false)}
              to={`/game/${item.name}`}
              key={key}
              className="resultCard"
            >
              <div
                className="card-logo"
                style={{
                  backgroundImage: "url(" + item.squareImage + ")",
                }}
              ></div>
              <div className="card-title">{item.name}</div>
            </Link>
          ))}
          {searchResult.length === 0 && (
            <div key="404NotFound" className="resultCardNotFound">
              <i className="bx bx-category-alt"></i>
              <div className="card-title">Nothing is there</div>
            </div>
          )}
        </div>
        <button onClick={() => setState(false)}>Close Search</button>
      </div>
    )
  );
}
