import Game1 from "./1.png";
import Game2 from "./2.png";
import Game3 from "./3.png";
import Game4 from "./4.png";

import VGame1 from "./vertical/1.png";
import VGame2 from "./vertical/2.png";
import VGame3 from "./vertical/3.png";
import VGame4 from "./vertical/4.png";

import heroGameTitle from "./plundur/title.png";
import loadingAnimation from "./loadingScreen.html";
// import "https://epicX67.github.io" from "./"https://epicX67.github.io".html";

export const categories = [
  {
    title: "Action",
    info: "Fight your way to the top in an interactive experience.",
    background:
      "https://cdn.discordapp.com/attachments/886692415572946984/1035944794876301413/image_17.png",
    ico: "ri-sword-line",
  },
  {
    title: "Survival",
    info: "Fight your way to the top in an interactive experience.",
    background:
      "https://cdn.discordapp.com/attachments/886692415572946984/1035945043950841886/Screen_Shot_2021-12-21_at_7.38.04_PM_-_Edited.png",
    ico: "ri-seedling-line",
  },
  {
    title: "Girls Games",
    info: "Fight your way to the top in an interactive experience.",
    background:
      "https://cdn.discordapp.com/attachments/886692415572946984/1035948625139871774/1.png",
    ico: "ri-women-line",
  },
  {
    title: "Boys Games",
    info: "Fight your way to the top in an interactive experience.",
    background:
      "https://cdn.discordapp.com/attachments/886692415572946984/1035948648166588669/2.png",
    ico: "ri-men-line",
  },
  {
    title: "3D Games",
    info: "Fight your way to the top in an interactive experience.",
    ico: "ri-shape-line",
  },
  {
    title: "2D Games",
    info: "Fight your way to the top in an interactive experience.",
    ico: "ri-game-line",
  },
  {
    title: "Adventures",
    info: "Fight your way to the top in an interactive experience.",
    background:
      "https://media.discordapp.net/attachments/832063991403642970/868672635419123773/ADVENTURE.png?width=2400&height=767",
    ico: "ri-treasure-map-line",
  },
  {
    title: "Arcade",
    info: "Fight your way to the top in an interactive experience.",
    background:
      "https://cdn.discordapp.com/attachments/886692415572946984/1035947163227787334/unknown.png",
    ico: "ri-gamepad-line",
  },
  {
    title: ".io Games",
    info: "Fight your way to the top in an interactive experience.",
    background:
      "https://cdn.discordapp.com/attachments/886692415572946984/1035948716919619584/8.png",
    ico: "ri-mouse-line",
  },
  {
    title: "Multiplayer",
    info: "Fight your way to the top in an interactive experience.",
    background:
      "https://cdn.discordapp.com/attachments/886692415572946984/1035948254019473489/image_18.png",
    ico: "ri-team-line",
  },
  {
    title: "Racing",
    info: "Fight your way to the top in an interactive experience.",
    background:
      "https://media.discordapp.net/attachments/832063991403642970/869549245378744330/Untitled_design_13.png",
    ico: "ri-roadster-line",
  },
  {
    title: "Popular",
    info: "Fight your way to the top in an interactive experience.",
    background:
      "https://cdn.discordapp.com/attachments/886692415572946984/1035948205969506314/image_19.png",
    ico: "ri-award-line",
  },
  {
    title: "Casual",
    info: "Fight your way to the top in an interactive experience.",
    background:
      "https://cdn.discordapp.com/attachments/886692415572946984/1035948700251471892/10.png",
    ico: "ri-ping-pong-line",
  },
];

export const collections = [
  {
    name: "Top Games of 2016",
    target: "tg2016",
    bg: "https://cdn.discordapp.com/attachments/886692415572946984/1040981799922315315/unknown.png",
  },
  {
    name: "Top Games of 2017",
    target: "tg2017",
    bg: "https://cdn.discordapp.com/attachments/886692415572946984/1040981830205181962/unknown.png",
  },
  {
    name: "Top Games of 2018",
    target: "tg2018",
    bg: "https://cdn.discordapp.com/attachments/886692415572946984/1040981917291511858/unknown.png",
  },
  {
    name: "Top Games of 2019",
    target: "tg2019",
    bg: "https://cdn.discordapp.com/attachments/886692415572946984/1040981957552635904/unknown.png",
  },
  {
    name: "Top Games of 2020",
    target: "tg2020",
    bg: "https://cdn.discordapp.com/attachments/886692415572946984/1040981999856406538/unknown.png",
  },
  {
    name: "Top Games of 2021",
    target: "tg2021",
    bg: "https://cdn.discordapp.com/attachments/886692415572946984/1040982033003991160/unknown.png",
  },
  {
    name: "Top Games of 2022",
    target: "tg2022",
    bg: "https://cdn.discordapp.com/attachments/886692415572946984/1040982292488781864/unknown.png",
  },
];

// credit :: https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export const shuffle = (array, limit = 8) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array.slice(0, limit);
};

export const filterBy = (key, data) => {
  return data.filter((item) => (item[key] ? true : false));
};

export const filterByValue = (key, value, data) => {
  return data.filter((item) => (item[key] === value ? true : false));
};

export const data = [
  {
    title: "Plundur",
    cover: Game1,
    vcover: null,
    url: "https://krunker.io",
    loading: loadingAnimation,
    tags: ["Adventure", "3D Game"],
  },
  {
    title: "Map magic",
    cover: Game2,
    vcover: null,
    url: "https://krunker.io",
    loading: loadingAnimation,
    tags: ["Adventure", "3D Game"],
  },
  {
    title: "Search mania",
    cover: Game3,
    vcover: null,
    url: "https://krunker.io",
    loading: loadingAnimation,
    tags: ["Puzzle", "2D Game"],
  },
  {
    title: "Code now",
    cover: Game4,
    vcover: null,
    url: "https://krunker.io",
    loading: loadingAnimation,
    tags: ["Puzzle", "2D Game"],
  },
  {
    title: "Plundur",
    cover: Game1,
    vcover: null,
    url: "https://krunker.io",
    loading: loadingAnimation,
    tags: ["Adventure", "3D Game"],
  },
  {
    title: "Krunker",
    cover: null,
    vcover: VGame1,
    url: "https://krunker.io",
    loading: loadingAnimation,
    tags: ["Action", "3D Game"],
  },
  {
    title: "Competitive",
    cover: null,
    vcover: VGame2,
    url: "https://krunker.io",
    loading: loadingAnimation,
    tags: ["Action", "3D Game"],
  },
  {
    title: "Casual",
    cover: null,
    vcover: VGame3,
    url: "https://krunker.io",
    loading: loadingAnimation,
    tags: ["Action", "3D Game"],
  },
  {
    title: "Fight ++",
    cover: null,
    vcover: VGame4,
    url: "https://krunker.io",
    loading: loadingAnimation,
    tags: ["Action", "3D Game"],
  },
];

export const heroGames = [
  {
    title_logo: heroGameTitle,
    title: "Plundur.io",
    cover:
      "https://cdn.discordapp.com/attachments/886692415572946984/985601813812219934/Screen_Shot_2022-06-12_at_1.49.18_PM.png",
    url: "https://krunker.io",
    loading: loadingAnimation,
    info: "One of the newest titles to debut in the IO-game industry, a brand new multiplayer survival game by Legion Platforms.",
  },
  {
    title_logo:
      "https://cdn10.idcgames.com/storage/image/1101/valorant-logo-idc/default.png",
    title: "Plundur.io",
    cover:
      "https://otakukart.com/wp-content/uploads/2021/05/valorant-Title-Image.jpg",
    url: "https://krunker.io",
    loading: loadingAnimation,
    info: "One of the newest titles to debut in the IO-game industry, a brand new multiplayer survival game by Legion Platforms.",
  },
];
