import React, { useState, useEffect, useMemo } from "react";
import "./Games.scss";
import { collections } from "../res/data";
import { useParams, useNavigate } from "react-router-dom";

export default function Collection({ games }) {
  const params = useParams();
  const navigate = useNavigate();
  const [collectionName, setCollectionName] = useState("");
  const [collectionBackground, setCollectionBackground] = useState(null);
  const [collectionTarget, setCollectionTarget] = useState(null);
  const data = useMemo(() => games, [games]);

  const handleItemClick = (url) => {
    navigate(url);
  };

  const tagFound = (tags) => {
    return tags.find((item) =>
      collectionTarget === null
        ? false
        : item.toLowerCase() === collectionTarget.toLowerCase()
    )
      ? true
      : false;
  };

  useEffect(() => {
    const collection = collections.find(
      (item) => item.name.toLowerCase() === params.name.toLowerCase()
    );

    if (!collection || collection.target === "") {
      navigate("/404", { replace: true });
    } else {
      setCollectionName(collection.name);
      setCollectionBackground(collection.bg ? collection.bg : null);
      setCollectionTarget(collection.target);
    }

    window.scrollTo(0, 0);
  }, [navigate, params]);

  return (
    <div className="games-container">
      <div
        className="games-hero"
        style={
          collectionBackground && {
            backgroundImage: `url('${collectionBackground}')`,
          }
        }
      >
        <p className="title">{collectionName}</p>
      </div>

      <div className="game-list">
        {data.map(
          (item, key) =>
            tagFound(item.category ? item.category.split(",") : []) && (
              <div
                key={key}
                className="content-card"
                style={{
                  backgroundImage: "url(" + item.wideImage + ")",
                }}
                onClick={() => handleItemClick(`/game/${item.name}`)}
              >
                <div className="card-info">{item.name}</div>
              </div>
            )
        )}
      </div>
    </div>
  );
}
