import React, { useEffect, useMemo, useState } from "react";
import "./Home.scss";
import Content from "../components/Content";
import VerticalContent from "../components/VerticalContent";
import Categories from "../components/Categories";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import { Link, useNavigate } from "react-router-dom";
import { categories, collections, filterByValue, shuffle } from "../res/data";
import CircleContent from "../components/CircleContent";
import SquareContent from "../components/SquareContent";
import WideContent from "../components/WideContent";
import Collections from "../components/Collections";
import GridContent from "../components/GridContent";
import GridContent2 from "../components/GridContent2";
import RowBar from "../components/RowBar";
import PortableRowTypeA from "../components/PortableRowTypeA";

export default function Home({ games, heroGames }) {
  const navigate = useNavigate();
  const [bg, setBg] = useState(
    heroGames.length > 0 ? heroGames[0].featuredImg : ""
  );
  const [video, setVideo] = useState(
    heroGames.length > 0 ? heroGames[0].video : ""
  );
  const [videoMode, setVideoMode] = useState(false);
  const [hideVideo, setHideVideo] = useState(false);

  useEffect(() => {
    if (heroGames.length > 0) {
      setBg(heroGames[0].featuredImg);
    }
  }, [heroGames]);

  const contents = useMemo(
    () => [
      shuffle(filterByValue("row", "Row 1", games)),
      shuffle(filterByValue("row", "Row 2", games)),
      shuffle(filterByValue("row", "Row 3", games)),
      shuffle(filterByValue("row", "Row 4", games)),
      shuffle(filterByValue("row", "Row 5", games)),
      shuffle(filterByValue("row", "Row 6", games)),
      shuffle(filterByValue("row", "Row 7", games)),
      shuffle(filterByValue("row", "Row 8", games)),
      shuffle(filterByValue("row", "Row 9", games)),
      shuffle(filterByValue("row", "Row 10", games)),
      shuffle(filterByValue("row", "Row 11", games)),
      shuffle(filterByValue("row", "Row 12", games)),
      shuffle(filterByValue("row", "Row 13", games)),
      shuffle(filterByValue("gender (B, G, Both)", "B", games), 15),
      shuffle(filterByValue("gender (B, G, Both)", "B", games), 15),
      shuffle(filterByValue("gender (B, G, Both)", "B", games), 21),
    ],
    [games]
  );

  return (
    <div className="home">
      <section
        className="hero-wrapper"
        onMouseEnter={() => {
          setHideVideo(false);
          setVideoMode(true);
        }}
        onMouseLeave={() => {
          console.log("step 1");
          setHideVideo(true);
          console.log("step 2");

          setTimeout(() => {
            setVideoMode(false);
            console.log("step 3");
          }, 1000);
        }}
      >
        {heroGames.map((item, key) => (
          <>
            <div
              style={{ backgroundImage: `url(${item.featuredImg})` }}
              className={`bg-handler ${item.featuredImg !== bg && "hidden"}`}
              key={key}
            ></div>

            {videoMode && item.video === video && (
              <>
                <video
                  className="backgroundVideo"
                  style={{
                    animation: hideVideo
                      ? "revealMinus 1000ms linear forwards"
                      : "revealPlus 2000ms linear forwards",
                  }}
                  autoPlay
                  muted
                  loop
                  src={video}
                ></video>
                <div
                  style={{
                    animation: hideVideo
                      ? "revealMinus 1000ms linear forwards"
                      : "revealPlus 2000ms linear forwards",
                  }}
                  className="blackOverlay"
                ></div>
              </>
            )}
          </>
        ))}

        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            reverseDirection: false,
            stopOnLastSlide: false,
            pauseOnMouseEnter: true,
          }}
          className="mySwiper"
          fadeEffect={true}
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={(swiper) => {
            setBg(heroGames[swiper.activeIndex].featuredImg);
            setVideo(heroGames[swiper.activeIndex].video);
          }}
        >
          {heroGames.map((item, key) => (
            <SwiperSlide key={key} className="hero">
              <div className="hero-card">
                <div className="game-head">
                  <img src={item.gameLogo} alt=""></img>
                </div>
                <div className="game-info">
                  {/* <div className="name">{item.name}</div> */}
                  <div className="info">{item.featuredDesc}</div>
                  <Link className="play-btn" to={`/game/${item.name}`}>
                    <i className="bx bx-play"></i>Play
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <PortableRowTypeA title="Popular" data={contents[15]} />
      <GridContent2 title="Good Games 1" data={contents[15]} circle={true} />
      <GridContent2 title="Good Games 2" data={contents[15]} circle={false} />
      <GridContent2
        title="Good Games 2 Wide"
        data={contents[15]}
        circle={false}
        extraWide={true}
      />
      <GridContent title="Good Games 3" data={contents[13]} circle={true} />
      <GridContent title="Good Games 4" data={contents[14]} circle={false} />
      <GridContent
        title="Good Games 4 wide"
        data={contents[14]}
        circle={false}
        extraWide={true}
      />
      <Content title="Featured" data={contents[0]} />
      <RowBar
        action={() => {
          if (games.length > 0) {
            navigate(`/game/${shuffle(games)[0].name}`);
          }
        }}
      />
      <Categories title="Categories" data={categories} />
      <VerticalContent title="Most Popular" data={contents[1]} />
      <WideContent title="Big Shot Games" data={contents[12]} />
      <Collections title="Collections" data={collections} />
      <CircleContent title="Legacy Franchises" data={contents[2]} />
      <Content title="Noteworthy Games" data={contents[3]} />
      <SquareContent title="Entertaining Games" data={contents[4]} />
      <Content title="Party Games" data={contents[5]} />
      <Content title="Top Rated Games" data={contents[6]} />
      <SquareContent title="Classic Games" data={contents[7]} />
      <Content title="Trending Games" data={contents[8]} />
      <Content title="Adventure Games" data={contents[9]} />
      <Content title="Newly Popular" data={contents[10]} />
      <SquareContent title="Player favorites" data={contents[11]} />
    </div>
  );
}
