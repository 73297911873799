import React from "react";
import "./Notfound.scss";
import { useNavigate } from "react-router-dom";

export default function Notfound() {
  const navigate = useNavigate();
  return (
    <div className="notfoundViewPort">
      <i class="bx bx-error">
        <span>404</span>
      </i>
      <div className="info">
        The resource which you are looking for is not available.
      </div>
      <button onClick={() => navigate(-1)}>Go Back</button>
    </div>
  );
}
